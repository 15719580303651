<template>
    <div>
        <v-textarea
            v-model="pattern"
            outlined
            hide-details
            rows="3"
            :placeholder="$trans('questions.hints.fill_text_set_example')"
        >
        </v-textarea>
        <slot></slot>
        <div class="mt-2">
            <small class="font-weight-semibold">{{ $trans('Preview') }}</small>
            <div class="theme-outline rounded-sm pa-2" style="min-height: 42px">
                <fill-text-process
                    :value="{ text, correct }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
import FillTextProcess from '@apps/questions/components/QuestionsType/QuestionsTypeInput/FillTextProcess'
export default {
    name: 'FillTextInput',
    components: { FillTextProcess },
    mixins: [questionsTypeBasePropsMixin],
    watch: {
        pattern(pattern) {
            this.buildCorrect(pattern)
        }
    },
    data() {
        return {
            pattern: null,
            text: [],
            correct: null
        }
    },
    mounted() {
        if(this.value.pattern) {
            this.pattern = this.value.pattern
        }

        if(this.value.correct) {
            this.correct = this.value.correct
        }
    },
    methods: {
        buildCorrect(pattern) {
            const correct = {}

            let index = 0
            let word = ''
            let answer = ''
            let fillAnswer = false
            const text = []

            for (let i = 0; i < pattern.length; i++) {
                const isLastChar = (i + 1) === pattern.length
                const nextChar = (i + 1) < pattern.length ? pattern[i + 1] : null
                const prevChar = i > 0 ? pattern[i - 1] : null
                const char = pattern[i]
                const answerKey = '__input__' + index

                if(prevChar === '[') {
                    fillAnswer = true
                }

                if(char === ']' && !!answer) {
                    correct[answerKey] = answer
                    text.push(answerKey)

                    fillAnswer = false
                    index++
                    answer = ''
                }

                if(fillAnswer) {
                    answer += char
                } else if(!['[', ']'].includes(char)) {
                    word += char
                }

                if(nextChar === '[' || isLastChar) {
                    text.push(word)
                    word = ''
                }
            }

            const options = {
                pattern,
                text,
                correct: Object.keys(correct).length > 0
                    ? correct
                    : null
            }

            this.text = text
            this.correct = correct

            this.$emit('input', options)
        }
    }
}
</script>
