<template>
    <div
        v-if="value"
        class="fill-text-process"
    >
        <template
            v-for="(char) in value.text"
        >
            <template
                v-if="char && char.startsWith('__input__')"
            >
                <input
                    :key="char"
                    type="text"
                    :size="value.correct[char].length * 1.5"
                    class="fill-text-process__input theme-gray-bg theme-outline"
                    @input="($event) => onProcess($event.target.value, char)"
                />
            </template>
            <template
                v-else
            >
                {{ char }}
            </template>
        </template>
    </div>
</template>

<script>
import _ from 'lodash'
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
export default {
    name: 'FillTextProcess',
    mixins: [questionsTypeBasePropsMixin],
    data: () => ({
        result: {
            reply: {},
            passed: false
        }
    }),
    methods: {
        onProcess(value, char) {
            const correct = this.value.correct
            const result = this.result

            if(value.length > 0) {
                result.reply[char] = value
            } else {
                delete result.reply[char]
            }

            const correctSize = Object.keys(correct).length
            const replySize = Object.keys(result.reply).length

            let process = null

            if(correctSize === replySize) {
                result.passed = _.isEqual(correct, result.reply)

                process = {
                    options: this.value,
                    score: result.passed ? this.score : 0,
                    defaultScore: this.score,
                    ...result
                }
            }

            this.$emit('process', process)
        }
    }
}
</script>

<style lang="scss" scoped>
    .fill-text-process {
        &__input {
            margin: 2px 0;
            padding: 2px 6px;
            outline: none;
            border-radius: 5px;
            transition: all .3s;

            &:focus {
                border-color: var(--v-primary-base);
            }
        }
    }
</style>
